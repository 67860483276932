import * as React from 'react';
import LayoutConsumer from '../components/layout-context';
import Seo from '../components/seo';
import PipedriveForm from '../components/pipedrive-form';

function ContactPage() {
  return (
    <LayoutConsumer>
      {({ set }) => {
        set({ pageTitle: 'Kontakt', fullWidth: true });
        return (
          <>
            <Seo
              title="Shopify Hilfe - Support von Shopify Experten"
              description="Sie brauchen Hilfe bei Ihrem Shopify Projekt? Wir als zertifizierte Shopify Experts stehen Ihnen gerne mit Rat & Tat zur Seite."
            />
            <div className="flex flex-col pt-20 lg:pt-[104px] px-4 lg:px-16 mb-36">
              <PipedriveForm
                tag="h1"
                title="Ihr Kontakt zu Shopify-Experten!"
                text="Erhalten sie Shopify-Hilfe für Ihre Idee. Als Shopify Experts helfen wir Ihnen gern bei Ihren Projekten. Egal, ob Migration, Shop Set-Up, App Entwicklung, Shop Anpassungen oder Marketing, nehmen Sie Kontakt auf und besprechen Sie Ihr Vorhaben mit uns - ganz unverbindlich!"
              />
            </div>
          </>
        );
      }}
    </LayoutConsumer>
  );
}

export default ContactPage;
